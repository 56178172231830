.ribbon {
  margin: 0;
  padding: 0;
  background: rgb(253, 224, 71);
  color:black;
  padding:1em 0;
  position: absolute;
  bottom:0;
  right:5%;

}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  bottom:0;
  width: 50%;
  height: 100%;
  background: rgb(253, 224, 71);
}
.ribbon:before {
  right:100%;
}

.ribbon:after {
  left:100%;
}